import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';

class ContextMenuTestPage extends PageElement {
  
  static get styles() {
    return [
      super.styles,
      styles,
      css`
        .container {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 100%;
          gap:50px;
          margin-top: 50px;
        }

        .container > * {
          width: 100px;
          height: 100px;
          background-color: lightblue;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .clicked {
          background-color: lightgreen;
        }
      `
    ];
  }

  constructor() {
    super();
    this.debug = false;
    this.selected = null;
  }

  onContextMenu(event, options) {
    event.preventDefault();
    this.onClick(event, options);
  }

  onClick(event) {
    for (const el of this.shadowRoot.querySelectorAll('block')) {
      el.classList.remove('clicked');
    }

    event.target.classList.toggle('clicked');
    this.selected = event.target.id;
    this._log.debug('selected:', this.selected);
    this.generateContextMenu();
    this.contextMenu.show(event.clientX, event.clientY);
  }

  generateContextMenu() {
    this.contextMenu = this.contextMenu || this.shadowRoot.querySelector('context-menu');
    this.contextMenu.content = html`
      <sl-menu>
        <sl-menu-item><b>${this.selected}</b></sl-menu-item>
        <sl-menu-item @click="${() => alert(`copy ${this.selected}`)}"><m-icon slot="prefix" name="content_copy"></m-icon>Copy</sl-menu-item>
        <sl-menu-item @click="${() => alert(`paste ${this.selected}`)}"><m-icon slot="prefix" name="content_paste"></m-icon>Paste</sl-menu-item>
        <sl-menu-item @click="${() => alert(`delete ${this.selected}`)}"><m-icon slot="prefix" name="delete"></m-icon>Delete</sl-menu-item>
      </sl-menu>
    `;
  }

  render() {
    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      
      <div class="title">
        <h3>&lt;context-menu&gt;</h3>
        <div>Menu contextuel</div>
      </div>

      <sl-tab-group>
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">

          Le contenu du menu contextuel varie en fonction de l'élément cliqué.
          Son contenu est généré à la selection de l'élément (<b>click</b>) mais réellement affiché lors du click droit (<b>contextmenu</b>).
          Plusieurs <b>&lt;context-menu&gt;</b> peuvent être déclaré par le composant appelant, mais un <b>seul container</b> (DOM) sera utilisé et rafraîchie.
          Ce dernier est créé si il n'existe pas lors de l'instanciation du premier &lt;context-menu&gt; et il est inséré dans le body du DOM et non pas
          dans le shadowRoot du composant et ce afin de ne pas avoir de problème de positionnement et d'affichage lorsque
          le composant appelant ou parent(s) utilise par exemple <b><i>overflow:hidden</i></b> mais surtout <b><i>position:fixed</i></b>
          <div class="container">
            <block
              id="block1"
              @click="${this.onClick}"
              @contextmenu="${(event) => this.onContextMenu(event, { id : 'block1' })}"
            >right click me
            </block>
            <block
              id="block2"
              @click="${this.onClick}"
              @contextmenu="${(event) => this.onContextMenu(event, { id: 'block2' })}"
            >right click me
            </block>
          </div>
          <context-menu></context-menu>
        </sl-tab-panel>
      </sl-tab-group>
    `;
  }
}

customElements.define('page-tests-context-menu', ContextMenuTestPage);