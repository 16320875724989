import { PageElement, html, css } from 'Elements';
import styles from '../styles.css.js';

import './tab_static.js';
import './tab_realtime.js';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      styles,
      css``
    ];
  }

  firstUpdated() {
    super.firstUpdated();
    this.tab = {
      realtime:this.shadowRoot.querySelector('tab-apex-realtime'),
      static:this.shadowRoot.querySelector('tab-apex-static')
    }
  }

  showTab(ev) {
    if (ev.detail.name === 'realtime') {
      this.tab.realtime.show();
    } else {
      this.tab.realtime.hide();
    }
  }

  render() {

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;apex-chart&gt;</h3>
        <div>Un wrapper on top of <a href="https://apexcharts.com/" target="apex">apexcharts.com</a>.</div>
      </div>

      <tab-group @sl-tab-show=${this.showTab}>
        <sl-tab slot="nav" panel="static">Statique</sl-tab>
        <sl-tab slot="nav" panel="realtime">Temps réel</sl-tab>

        <sl-tab-panel name="static" style="overflow:hidden;">
          <tab-apex-static></tab-apex-static>
        </sl-tab-panel>

        <sl-tab-panel name="realtime">
          <tab-apex-realtime debug showNative></tab-apex-realtime>
        </sl-tab-panel>
      </tab-group>

      <br/><br/><br/>
    `;
  }
}

customElements.define('page-tests-apexcharts', Page);
