import { PageElement, html, css } from 'Elements';

import '../../../../public/cti/edit.js';

class TicketDraftsEditPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display:grid;
          grid-template-columns: repeat(6, 1fr);
          margin-right:20px;
          gap:20px;
          font-size:0.9em;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
          }
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            internationalisation: 'Internationalization',
            languages_site: 'Website languages',
            automatic_translation: 'Automatic translation'
          },
        },
        french:{
          translation: {
            internationalisation: 'Internationalisation',
            languages_site: 'Langues du site',
            automatic_translation: 'Traduction automatique'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.layout = '';
  }

  render() {
    return html`
      <section-header backroute="../../" micon="edit_square">
        Mes brouillons
      </section-header>
      <br/>
      <page-cti-edit></page-cti-edit>
      <br/>
    `;
  }

}

customElements.define('page-cus-tickets-draft-edit', TicketDraftsEditPage);