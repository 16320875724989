import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';

class CherryEditorPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        pre {
          padding:20px;
          margin:10px;
          font-size:0.9em;
          border:1px solid var(--sl-color-neutral-300)
        }

        p {
          font-size:1.2em;
          margin-left:40px;
        }

        .flex {
          display:flex;
          align-items:center
        }
      `
    ]
  }

  render() {
    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>

      <div class="title">
        <h3>&lt;cherry-editor&gt;</h3>
        <div>Un wrapper on top of <a href="https://github.com/Tencent/cherry-markdown" target="cherry">cherry-markdown</a>.</div>
      </div>
   
      <sl-tab-group>
        
        <sl-tab slot="nav" panel="tab1">Composant</sl-tab>
        <sl-tab slot="nav" panel="tab2">Status</sl-tab>
        <sl-tab-panel name="tab1">
          <cherry-editor></cherry-editor>
        </sl-tab-panel>

        <sl-tab-panel name="tab2">
          Cherry Editor est un éditeur markdown très puissant.<br/><br/>
          Ceci dit, son code est réalisé par des étudiants, le github est majoritairement en chinois, la documentation n'est pas à jour.<br/><br/>
          Il est en théorie étendable mais pour l'instant choux blanc.<br/><br/>
          Toute alternative est à considérer.
        </sl-tab-panel>

      </sl-tab-group>

      <br/><br/>
    `;
  }

}

customElements.define('page-tests-cherry-editor', CherryEditorPage);