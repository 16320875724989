import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';

class GroupPeriodTestPage extends PageElement {
  
  static get styles() {
    return [
      super.styles,
      styles,
      css``
    ];
  }

  static get properties() {
    return {
      period: { type: String },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.period = 'day';
  }

  periodChange(e) {
    this.period = e.detail
  }

  render() {
    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3><b>&lt;data-periods-group&gt;</b></h3>
        <div>Selecteur de période pour regrouper des éléments</div>
      </div>

      <sl-tab-group>
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">
          <data-periods-group @change=${this.periodChange}></data-periods-group>
          <br/><br/>
          Sélection: ${this.period}
        </sl-tab-panel>
      </sl-tab-group>
    `;
  }
}

customElements.define('page-tests-data-periods-group', GroupPeriodTestPage);