import { PageElement, html, css } from 'Elements';

import styles from './styles.css.js';

class ContractSLAPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        p {
          font-size:1.2em;
          margin-left:40px;
        }

        .flex {
          display:flex;
          align-items:center;
          width:100%;
          gap:10px;
          align-items:flex-start;
        }

        .flex * {
          flex:1;
        }

        .label {
          width:250px;
          flex:initial;          
        }

        .switch {
          display:flex;
          justify-content:flex-start;
          margin:20px;
        }

      `
    ]
  }

  static get properties() {
    return {
      value: { type: Object },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value = null;
  }

  onChange(ev) {
    this.value = { ...ev.detail };
    this._log.debug('onChange', this.value);
  }

  onChangeStatus(ev) {
    this.qs('contract-sla').readOnly = ev.target.checked;
  }

  render() {

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;contract-sla&gt;</h3>
        <div>Un composant pour paramétrer le SLA</div>
      </div>

      <sl-tab-group>
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">
          <div>
            <div class="switch"><sl-switch @sl-change=${this.onChangeStatus}></sl-switch>Lecture seule (vue client)</div>
          </div>

          <div class="flex">
            <contract-sla @change=${this.onChange}></contract-sla>  
            <box-json style="max-width:300px" .value=${this.value}></box-json>
          </div>

        </sl-tab-panel>

      </sl-tab-group>

      <br/><br/><br/>
    `;
  }
}

customElements.define('page-tests-contract-sla', ContractSLAPage);