import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';

class DataPaginatorTestPage extends PageElement {
  
  static get styles() {
    return [
      super.styles,
      styles,
      css`
      `
    ];
  }

  static get properties() {
    return {
      page: { type: String },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.page = 1;
  }

  pageChange(e) {
    this.page = e.detail
  }

  render() {
    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3><b>&lt;data-paginator&gt;</b></h3>
        <div>Selecteur de pages</div>
      </div>

      <sl-tab-group>
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">
          <data-paginator pages=50 value=20 @change=${this.pageChange}></data-paginator>
          <br/><br/>
          Sélection: ${this.page}
        </sl-tab-panel>
      </sl-tab-group>
    `;
  }
}

customElements.define('page-tests-data-paginator', DataPaginatorTestPage);