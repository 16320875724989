import { BaseElement, html, css } from 'Elements';
import { Random } from 'Utils';

const DEFAULT_CHART_TYPE = 'line';

import './tab_apex_editor.js';

class TabApexStatic extends BaseElement {
  static get styles() {
    return [
      css`
        .graphs_static {
          display:flex;
          justify-content:space-around;
          gap:10px;
        }

        apex-chart {
          display:block;
          flex:1;
        }
      `
    ];
  }

  constructor() {
    super();
    this.debug = false;
    this.randomizeGraphData = this.randomizeGraphData.bind(this);
  }

  firstUpdated() {
    super.firstUpdated();
    // apex editor tab is waiting for chart to be ready
    this.chart = this.shadowRoot.querySelector('apex-chart');
  }

  randomizeGraphData(ev) {
    for (let i = 0; i < this.staticSeriesTime.length; i++) {
      this.staticSeriesTime[i].data = this.staticSeriesTime[i].data.map((/*v*/) => {
        //if (v<3) return 0;
        return Random.between(0, 100);
      });
    }

    if (ev) {
      this.shadowRoot.querySelector('apex-chart').series = [...this.staticSeriesTime];
    }
  }
  
  prepareStaticData() {
    const randomArray = Array(12).fill(0);

    this.staticColors = [ '#a50b0b', '#a6490a', '#2a6b2a' ];
    this.staticLabelsTime = [ 'Jan', 'Fév', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc' ];
    this.staticSeriesTime = [
      { name: 'Série 1',  data: [ ...randomArray ] },
      { name: 'Série 2',  data: [ ...randomArray ] },
      { name: 'Série 3',  data: [ ...randomArray ] }
    ]
 
    this.randomizeGraphData();
  }

  applyChartConfig() {
    const chart = this.qs('apex-chart2');
    const options = JSON.parse(this.qs('sl-textarea').value);
    chart.updateOptions(options, true);
  }

  /*
  getChartType() {
    const chart = this.shadowRoot.querySelector('apex-chart2');
    if (!chart) return DEFAULT_CHART_TYPE;
    return chart.type;
  }
  */

  render() {

    this.prepareStaticData();

    return html`
      <div class="graphs_static">
        <!--
        <div class="graph_static_container">
          <div>
          -->
            <apex-chart id="chart1" type="${DEFAULT_CHART_TYPE}"
              .series=${this.staticSeriesTime}
              .labels=${this.staticLabelsTime}
              .colors=${this.staticColors}
            ></apex-chart>
          <!--</div>
        </div>-->
        <apex-static-editor style="width:30vw;" .parent=${this}></apex-static-editor>
      </div>
    `;
  }
}

customElements.define('tab-apex-static', TabApexStatic);
