import { BaseElement, html, css, formCss } from 'Elements';
const DEFAULT_CHART_TYPE = 'area';

class TabApexStaticEditor extends BaseElement {
  static get styles() {
    return [
      formCss,
      css`
        :host {
          height:100vh;
          width:100%;
          display:flex;
          flex-direction:column;
          gap:7px;
          padding:5px;
          width:30vw;
          border:1px solid var(--sl-color-neutral-300);
          height:67vh;
        }

        .static_container {
          border:1px solid var(--sl-color-neutral-300);
          width:100%;
          display:block;
          overflow:hidden;
          min-height: 68vh;
          height: 68vh;
          max-height: 68vh;
          position:relative;
        }

        sl-details::part(header) {
          margin:0;
          padding:10px;
          border-bottom:1px solid var(--sl-color-neutral-300);
        }

        sl-details::part(content) {
          margin:0;
          margin-left:10px;
          margin-right:10px;
          padding:4px;
          background-color:transparent;
        }

        .parameters {
          display:flex;
          flex-direction:column;
          padding-top:4px;
          padding-bottom:4px;
        }

        .parameters > * {
          display:block;
        }

        sl-textarea::part(textarea) {
          font-size:0.8em;
          height: 84vh;
        }

        small {
          font-size:1em;
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.loading = true;
    this.onThemeChange = this.onThemeChange.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('theme-change', this.onThemeChange);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('theme-change', this.onThemeChange);
    clearInterval(this.loopInterval);
  }

  onThemeChange(e) {
    this._log.debug(this.id, 'onThemeChange', e.detail);
    const value = e.detail.isDark ? 'dark' : 'light';
    this.qs('sl-select[name="gradientShade"]').value = value;
  }

  firstUpdated() {
    super.firstUpdated();
    if (this.chart) return;
    this._log.debug('firstUpdated');

    // wait for this.parent.chart to be available
    this._log.debug('firstUpdated: waiting for this.parent.chart', this.parent.chart);
    const initInterval = setInterval(async () => {
      if (this.parent.chart) {
        clearInterval(initInterval);
        this.chart = this.parent.chart;
        this._log.debug('firstUpdated: chart available', this.chart);
        this.loading = false;
        await this.requestUpdate();
        this.initChartForm();
        return;
      }
      this._log.debug('firstUpdated: waiting for this.parent.chart', this.parent.chart);
    }, 300);
  }

  timeSeriesToCumulative(series) {
    const newSeries = [];
    for (let i = 0; i < series.length; i++) {
      const data = series[i].data;
      let cumulative = 0;
      const newData = data.map((d) => {
        cumulative += d;
        return cumulative;
      });
      newSeries.push({ name: series[i].name, data: newData });
    }
    return newSeries;
  }

  initChartForm() {
    const fields = this.qs('sl-switch,sl-select,sl-input', true);
    this._log.debug('initChartForm', fields);

    for (const field of fields) {
      const tagName = field.tagName.toLowerCase();
      this._log.debug('initChartForm', tagName, field.name);
      
      field.classList.add('labol');
      field.size='small';

      if (tagName === 'sl-switch') {
        field.addEventListener( 'sl-change', e => {
          this.chart[field.name] = e.target.checked }
        );
        field.checked = this.chart[field.name];
      }

      if (tagName === 'sl-select') {
        field.addEventListener('sl-change', e => {
          this.chart.setAttribute(field.name, e.target.value) 
          console.log(this.chart);
      });
        field.value = this.chart[field.name];
      }

      if (tagName === 'sl-input') {
        field.addEventListener('sl-change', e => this.chart.setAttribute(field.name, e.target.value) );
        field.value = this.chart[field.name];
      }
    }
  }

  editStaticGraphOptions() {
    this.modalEdit = this.modalEdit || this.qs('modal-drawer');
    this.modalEdit.show();
    this.textareaGraphConfig = this.textareaGraphConfig || this.qs('sl-textarea');
    this.textareaGraphConfig.value = JSON.stringify(this.chart.chart.opts, null, 4);
  }

  applyChartConfig() {
    const options = JSON.parse(this.textareaGraphConfig.value);
    this.chart.updateOptions(options);
  }

  renderOptionsChartType() {
    return html`
      <small>Timeseries</small>
      <sl-option value="line">line</sl-option>
      <sl-option value="area">area</sl-option>
      <sl-option value="bar">bar</sl-option>
      <sl-option value="heatmap">heatmap</sl-option>
      <sl-option value="polarArea">polarArea</sl-option>

      <sl-divider></sl-divider>
      <small>Cumulative</small>
      <sl-option value="pie">pie</sl-option>
      <sl-option value="donut">donut</sl-option>
      <sl-option value="radialBar">radialBar</sl-option>
      <sl-option value="radar">radar</sl-option>

      <sl-divider></sl-divider>
      <small>Not yet implemented</small>
      <sl-option value="scatter">scatter</sl-option>
      <sl-option value="bubble">bubble</sl-option>
      <sl-option value="bubble3d">bubble 3D</sl-option>
      
      <sl-divider></sl-divider>
      <small>Not implemented</small>
      <sl-option value="candlestick">candlestick</sl-option>
      <sl-option value="boxPlot">boxPlot</sl-option>
      <sl-option value="rangeBar">rangeBar</sl-option>
      <sl-option value="rangeArea">rangeArea</sl-option>
      <sl-option value="treemap">treemap</sl-option>
    `
  }

  renderOptionsStrokeCurve() {
    return html`
      <sl-option value="straight">straight</sl-option>
      <sl-option value="smooth">smooth</sl-option>
      <sl-option value="monotoneCubic">monotoneCubic</sl-option>
      <sl-option value="stepline">stepline</sl-option>
    `
  }

  renderOptionsFillType() {
    return html`
      <sl-option value="solid">solid</sl-option>
      <sl-option value="gradient">gradient</sl-option>
    `
  }

  renderOptionsGradientShade() {
    return html`
      <sl-option value="light">light</sl-option>
      <sl-option value="dark">dark</sl-option>
    `
  }

  renderOptionsGradientType() {
    return html`
      <sl-option value="horizontal">horizontal</sl-option>
      <sl-option value="vertical">vertical</sl-option>
      <sl-option value="diagonal">diagonal</sl-option>
      <sl-option value="radial">radial</sl-option>
    `
  }

  getChartType() {
    const chart = this.shadowRoot.querySelector('apex-chart');
    if (!chart) return DEFAULT_CHART_TYPE;
    return chart.type;
  }

  render() {

    if (this.loading) {
      return html`
        <div style="display:block;text-align:center;">
          <br/><br/><br/><br/><br/><br/>
          <sl-spinner style="font-size: 5rem;"></sl-spinner>
        </div>
      `;
    }

    return html`
      <div style="display:flex;gap:5px;">
        <sl-button size="small" @click=${this.parent.randomizeGraphData}>Randomize</sl-button>
        <sl-button size="small" @click=${this.editStaticGraphOptions}>Edit</sl-button>
      </div>

      <sl-details summary="Chart" open>
        <div class="parameters">
          <sl-select name="type" label="Type" hoist>${this.renderOptionsChartType()}</sl-select>
          <sl-switch name="animationsEnabled">Animated</sl-switch>
          <sl-switch name="stacked">Stacked</sl-switch>
        </div>
      </sl-details>

      <sl-details summary="Stroke">
        <div class="parameters">
          <sl-select name="strokeCurve" label="Curve" hoist>${this.renderOptionsStrokeCurve()}</sl-select>
          <sl-input type="number" name="strokeWidth" label="Width"></sl-input>
        </div>
      </sl-details>

      <!--
      <sl-details summary="Fill">
        <div class="parameters">
          <sl-select name="fillType" label="Fill type" hoist>${this.renderOptionsFillType()}</sl-select>
          <sl-select name="fillGradientShade" label="Gradient Shade" hoist>${this.renderOptionsGradientShade()}</sl-select>
          <sl-select name="fillGradientType" label="Gradient Type" hoist>${this.renderOptionsGradientType()}</sl-select>
        </div>
      </sl-details>
      -->

      <sl-details summary="Gradient" open>
        <div class="parameters">
          <sl-select name="strokeFillType" label="Stroke" hoist>${this.renderOptionsFillType()}</sl-select>
          <sl-select name="fillType" label="Fill" hoist>${this.renderOptionsFillType()}</sl-select>
          <sl-select name="gradientShade" label="Shade" hoist>${this.renderOptionsGradientShade()}</sl-select>
          <sl-select name="gradientType" label="Type" hoist>${this.renderOptionsGradientType()}</sl-select>
          <sl-switch name="gradientInverseColors">Inverted</sl-switch>
        </div>
      </sl-details>
      
      <modal-drawer label="Edit">
        <sl-textarea spellcheck="false"></sl-textarea>
        <sl-button slot="bt1" variant="primary" @click=${this.applyChartConfig}>Appliquer</sl-button>
        <sl-button slot="bt2" close="true" variant="text">Annuler</sl-button>
      </modal-drawer>

    `;
  }
}

customElements.define('apex-static-editor', TabApexStaticEditor);
